import restaurant from "./images/res.jpeg";
import biserica from "./images/bis.jpg";
import img_card from "./images/picioruse.jpg";
import imgheadermb from "./images/urs_fon4m.jpg";
import imgheader from "./images/urs_fon4.jpg";
import imgheadermiini from "./images/urs_fon3.jpg";
import imgheadermiinimb from "./images/urs_fon3.jpg";
import logo from './images/logo-urs.png'



const data = {
    introData: [{
        copilul: "Gabriele",
        familia: "familia Gîncu",
        logo: logo,
        tata: "Petru",
        mama: "Elena",
        data: "1 Septembrie 2023",
        data_confirmare: "20 august 2023",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "anusic007@gmail.com", 
       tel: "+3284423936",
       phone: "tel:+3284423936",
       viber: "viber://chat?number=%2B3284423936",
       whatsapp: "https://wa.me/+3284423936",
       messenger: "https://www.messenger.com/t/lenacika.nazar",
       tel1: "+4xxx",
       phone1: "tel:+4xxx",
       viber1: "viber://chat?number=%2B4xxx",
       whatsapp1: "https://wa.me/+4xxxx",
       messenger1: "https://www.messenger.com/t/",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Sf. Botez",
            localul: "Biserica",
            name: "Sf. Nicolae",
            data: "1 septembrie 2023, vineri, ora 16:00",
            adress: "s. Hîrbovăț, r-l. Anenii Noi",
            harta: "https://goo.gl/maps/MzVCqBip3TEAMvEP9",
            iframe: ""
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "La Petrică Catering",
            data: "1 septembrie 2023, vineri, ora 18:00",
            adress: "s. Hîrbovăț, r-l. Anenii Noi",
            harta: "https://goo.gl/maps/8f9H71gzutdCQV859",
            iframe: ""
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Un copil este un dar al lui Dumnezeu, un motiv de a crede în minuni, este pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;